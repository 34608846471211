.games {

  &__wrapper {
  }

  &__title {
  }

  &__text {
    margin-bottom: 5rem;
    text-align: center;

    @media (max-width: em(767)) {
      margin-bottom: 3rem;
    }
  }

  &__link {
    margin-right: auto;
    margin-left: auto;
  }
}
