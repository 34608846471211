.deposit {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    right: -36rem;
    top: 0rem;
    z-index: -1;
    border-radius: 50%;
    width: 106rem;
    height: 106rem;
    background-color: var(--clr-primary-650);
    filter: blur(8rem);
  }

  &__wrapper {
    position: relative;
  }

  &__title {
    margin-bottom: 8rem;

    @media (max-width: em(1330)) {
      margin-bottom: 5rem;
    }

    @media (max-width: em(992)) {
      margin-bottom: 3rem;
    }
  }

  &__content {
    @media (max-width: em(1330)) {
      display: flex;
    }

    @media (max-width: em(992)) {
      flex-direction: column;
    }
  }

  &__info {
    max-width: 75rem;

    @media (max-width: em(1330)) {
      margin-right: 3rem;
      max-width: 55rem;
    }

    @media (max-width: em(992)) {
      margin-right: initial !important;
      margin-bottom: 3rem;
      max-width: initial !important;
    }
  }

  &__text {
    margin-bottom: 5rem;

    p:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__link {
  }

  &__image {
    position: absolute;
    right: -24rem;
    top: 14.5rem;
    width: 76rem;
    height: 55rem;

    @media (max-width: em(1330)) {
      position: initial;
      right: initial;
      top: initial;
      width: initial;
      height: initial;
      max-width: 76rem;
    }

    @media (max-width: em(992)) {
      margin-right: auto;
      margin-left: auto;
    }
  }
}
