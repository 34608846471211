.download {
  &__wrapper {
  }

  &__title {
  }

  &__text {
    p {
      text-align: center;

      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }
  }
}
