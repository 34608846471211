.bonus {

  &__wrapper {
  }

  &__title {
  }

  &__text {
    margin-bottom: 5rem;
    text-align: center;

    @media (max-width: em(767)) {
      margin-bottom: 3rem;
    }
  }

  &__content {
    position: relative;
    padding-top: 7rem;
    padding-bottom: 7rem;

    &::before {
      content: '';
      position: absolute;
      left: 50%;
      top: 0;
      width: calc(100% + 30rem);
      height: 100%;
      background-image: linear-gradient(90.24deg, rgba(0, 192, 177, 0.12) -3.21%, rgba(0, 143, 163, 0.02) 43.49%, rgba(0, 163, 153, 0.1) 88.14%);
      transform: translateX(-50%);
    }

    @media (max-width: em(992)) {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
    }
  }

  &__list {
    position: relative;
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: repeat(2, 1fr);

    &::before, &::after {
      content: '';
      position: absolute;
      width: 6rem;
      height: 12rem;
      background-color: var(--clr-default-800);
    }

    &::before {
      left: -16.5rem;
      top: -11rem;
      transform: rotate(45deg);
    }

    &::after {
      right: -16rem;
      bottom: -10.9rem;
      transform: rotate(45deg);
    }

    @media (max-width: em(767)) {
      display: block;
      grid-gap: initial;
    }
  }

  &__item {
    @media (max-width: em(767)) {
      &:not(:last-child) {
        margin-bottom: 2.5rem;
      }
    }
  }

  &__item-title {
    margin-bottom: 1.5rem;
    font-weight: 700;
    font-size: fluid-font(3.6rem, 2.1rem);
    color: var(--clr-default-500);

    @media (max-width: em(767)) {
      margin-bottom: 1rem;
    }
  }

  &__item-text {
    p:not(:last-child) {
      margin-bottom: 2rem;

      @media (max-width: em(767)) {
        margin-bottom: 1rem;
      }
    }
  }
}

