.online {

  &__wrapper {
  }

  &__title {
  }

  &__text {
    margin-bottom: 5rem;
    text-align: center;

    p:not(:last-child) {
      margin-bottom: 3rem;
    }

    @media (max-width: em(767)) {
      margin-bottom: 3rem;
    }
  }

  &__table {
    overflow: auto;
    border: 0.1rem solid var(--clr-primary-450);
    padding-top: 4rem;
    padding-right: 3rem;
    padding-bottom: 4rem;
    padding-left: 3rem;

    table {
      border-collapse: collapse;
      min-width: 100%;

      thead {
        th {
          padding-top: 1rem;
          padding-right: 0.5rem;
          padding-bottom: 1rem;
          padding-left: 0.5rem;
          line-height: 150%;
          text-align: start;
          color: var(--clr-default-550);
        }
      }

      tbody {
        tr {
          border-bottom: 0.1rem solid var(--clr-default-600);
        }

        td {
          padding-top: 1rem;
          padding-right: 0.5rem;
          padding-bottom: 1rem;
          padding-left: 0.5rem;
          line-height: 150%;
        }
      }

      @media (max-width: em(767)) {
        min-width: 74rem;
      }
    }

    @media (max-width: em(992)) {
      padding-top: 2rem;
      padding-right: 2rem;
      padding-bottom: 2rem;
      padding-left: 2rem;
    }
  }
}
