.info {
  position: relative;
  margin-top: -18rem;

  &__wrapper {
  }

  &__text {
    margin-bottom: 5rem;
    text-align: center;

    p:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__table {
    overflow: auto;
    margin-bottom: 3rem;
    border: 0.1rem solid var(--clr-default-500);
    padding-top: 4rem;
    padding-right: 3rem;
    padding-bottom: 4rem;
    padding-left: 3rem;

    table {
      border-collapse: collapse;

      tbody {
        tr {
          &:not(:last-child) {
            border-bottom: 0.1rem solid var(--clr-default-600);
          }
        }

        td {
          vertical-align: top;
          padding-top: 0.8rem;
          padding-right: 1.2rem;
          padding-bottom: 0.8rem;
          padding-left: 1.2rem;
          line-height: 150%;

          &:first-child {
            min-width: 32rem;

            @media (max-width: em(992)) {
              min-width: 23rem;
            }

            @media (max-width: em(767)) {
              min-width: 15rem;
            }
          }
        }
      }

      @media (max-width: em(575)) {
        min-width: 50rem;
      }
    }

    @media (max-width: em(575)) {
      padding-right: 1.5rem;
      padding-left: 1.5rem;
    }
  }
}
