.program {

  &__wrapper {
    margin-bottom: 8rem;

    @media (max-width: em(992)) {
      margin-bottom: 6rem;
    }

    @media (max-width: em(767)) {
      margin-bottom: 4rem;
    }
  }

  &__title {
  }

  &__text {
    margin-bottom: 5rem;
    text-align: center;

    p:not(:last-child) {
      margin-bottom: 2rem;
    }

    @media (max-width: em(767)) {
      margin-bottom: 3rem;
    }
  }

  &__link {
    margin-right: auto;
    margin-left: auto;
  }
}
