.features {
  &__wrapper {
  }

  &__title {
  }

  &__text {
    margin-bottom: 5rem;

    p {
      text-align: center;

      &:not(:last-child) {
        margin-bottom: 3rem;

        @media (max-width: em(575)) {
          margin-bottom: 2rem;
        }
      }
    }

    @media (max-width: em(767)) {
      margin-bottom: 3rem;
    }
  }

  &__link {
    margin-right: auto;
    margin-left: auto;
  }
}
