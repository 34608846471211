.faq {

  &__title {
  }

  &__text {
    margin-bottom: 5rem;

    @media (max-width: em(767)) {
      margin-bottom: 3rem;
    }
  }

  &__list {
    display: grid;
    grid-gap: 2.5rem;
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: em(992)) {
      grid-gap: 1.5rem;
    }

    @media (max-width: em(767)) {
      display: block;
      grid-gap: initial;
    }
  }

  &__item {
    border: 0.1rem solid var(--clr-default-500);
    border-radius: 1.2rem;
    padding: 3rem;

    @media (max-width: em(992)) {
      padding: 1.5rem;
    }

    @media (max-width: em(767))  {
      margin-bottom: 2rem;
    }
  }

  &__item-title {
    margin-bottom: 1.5rem;
    font-weight: 700;
    font-size: fluid-font(2.4rem, 1.8rem);
  }

  &__item-body {
  }
}
