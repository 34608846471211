.benefits {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: -49rem;
    top: -13rem;
    z-index: -1;
    border-radius: 50%;
    width: 106rem;
    height: 106rem;
    background-color: var(--clr-primary-650);
    filter: blur(8rem);
  }

  &::after {
    content: "";
    position: absolute;
    right: -60rem;
    top: 3rem;
    z-index: -1;
    border-radius: 50%;
    width: 106rem;
    height: 106rem;
    background-color: var(--clr-primary-650);
    filter: blur(8rem);
  }

  &__wrapper {
  }

  &__title {
  }

  &__text {
    margin-bottom: 5rem;
    text-align: center;

    p:not(:last-child) {
      margin-bottom: 2rem;
    }

    @media (max-width: em(767)) {
      margin-bottom: 3rem;
    }
  }

  &__body {
    display: flex;

    @media (max-width: em(767)) {
      flex-direction: column;
    }
  }

  &__content {
    &:first-child {
      margin-right: 5rem;

      @media (max-width: em(767)) {
        margin-bottom: 3rem;
      }
    }
  }

  &__content-title {
    margin-bottom: 2.5rem;
    font-weight: 700;
    font-size: fluid-font(3.6rem, 1.8rem);
    text-transform: uppercase;
    color: var(--clr-default-500);

    &--cons {
      color: rgba(0, 214, 218, 0.44);
    }
  }

  &__list {
    list-style: disc;
    padding-left: 2rem;
  }

  &__item {
    font-size: fluid-font(2rem, 1.8rem);

    &:not(:last-child) {
      margin-bottom: 2.5rem;

      @media (max-width: em(767)) {
        margin-bottom: 1.5rem;
      }
    }
  }
}

