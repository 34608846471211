.table {

  &__wrapper {
  }

  &__title {
  }

  &__text {
    margin-bottom: 5rem;
    text-align: center;

    p:not(:last-child) {
      margin-bottom: 2.5rem;
    }

    @media (max-width: em(767)) {
      margin-bottom: 3rem;
    }
  }

  &__info {
    overflow: auto;
    margin-bottom: 4rem;
    border: 0.1rem solid var(--clr-primary-400);
    padding-top: 4rem;
    padding-right: 3rem;
    padding-bottom: 4rem;
    padding-left: 3rem;

    table {
      border-collapse: collapse;
      min-width: 100%;
      thead {
        th {
          line-height: 150%;
          text-align: start;
          color: var(--clr-default-550);

          &:first-child {
            min-width: 25rem;

            @media (max-width: em(992)) {
              min-width: 15rem;
            }
          }

          &:nth-child(2) {
            min-width: 40rem;

            @media (max-width: em(992)) {
              min-width: 20rem;
            }
          }
        }
      }

      tbody {
        tr {
          border-bottom: 0.1rem solid var(--clr-default-600);
        }
        td {
          padding-top: 1rem;
          padding-right: 0.5rem;
          padding-bottom: 1rem;
          padding-left: 0.5rem;
          line-height: 150%;

          ul {
            list-style: disc;
            padding-left: 2rem;
          }
        }
      }

      @media (max-width: em(767)) {
        min-width: 66rem;
      }
    }

    @media (max-width: em(992)) {
      padding-top: 2rem;
      padding-right: 2rem;
      padding-bottom: 2rem;
      padding-left: 2rem;
    }
  }

  &__link {
    margin-right: auto;
    margin-left: auto;
  }
}

