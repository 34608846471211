.registration {
  position: relative;
  margin-top: 14rem;
  padding-top: 10rem;
  padding-bottom: 10rem;
  background-image: -webkit-image-set(
    url(../img/registration/bg@2x.png) 2x,
    url(../img/registration/bg@1x.png) 1x
  );
  background-size: 100% 100%;
  background-repeat: no-repeat;

  @media (max-width: em(1320)) {
    margin-top: 10rem;
  }

  @media (max-width: em(992)) {
    margin-top: 5rem !important;
  }

  &__wrapper {
  }

  &__title {
    @media (max-width: em(575)) {
      margin-bottom: 2rem;
    }
  }

  &__text {
    margin-right: auto;
    margin-bottom: 5rem;
    margin-left: auto;
    text-align: center;

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__list {
    counter-reset: num;
    list-style-type: none;
  }

  &__item {
    position: relative;
    padding-left: 10rem;

    &::before {
      counter-increment: num;
      content: counter(num);
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 7.4rem;
      height: 7.4rem;
      font-weight: 700;
      font-size: 3.4rem;
      color: var(--clr-default-100);
      background-color: var(--clr-default-500);

      @media (max-width: em(575)) {
        width: 4rem;
        height: 4rem;
        font-size: 2rem;
      }
    }

    &-title {
      margin-bottom: 1.5rem;
      font-weight: 700;
      font-size: fluid-font(3.6rem, 1.8rem);
      line-height: 133%;
      color: var(--clr-default-500);
    }

    &-text {
      p:not(:last-child) {
        margin-bottom: 2rem;
      }
    }

    &:not(:last-child) {
      margin-bottom: 5rem;

      &::after {
        content: '';
        position: absolute;
        left: 3.5rem;
        top: 0;
        z-index: -1;
        width: 0.4rem;
        height: calc(100% + 7.4rem);
        background-color: var(--clr-default-500);

        @media (max-width: em(575)) {
          left: 1.9rem;
          height: calc(100% + 4rem);
        }
      }

      @media (max-width: em(575)) {
        margin-bottom: 2.5rem;
      }
    }

    @media (max-width: em(575)) {
      padding-left: 5rem;
    }
  }

  @media (max-width: em(767)) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
