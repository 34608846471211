.resume {

  &__wrapper {
  }

  &__title {
  }

  &__text {
    text-align: center;

    p:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
}
