/* stylelint-disable */

/* stylelint-disable */

/* stylelint-disable scss/operator-no-unspaced */

/* stylelint-disable scss/dollar-variable-colon-newline-after */

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "ZonaPro";
  src: url("../fonts/ZonaPro-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Strong";
  src: url("../fonts/Strong-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "ZonaPro";
  src: url("../fonts/ZonaPro-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

html {
  font-family: var(--font-family);
  font-weight: var(--font-weight);
  font-size: 62.5%;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}

body {
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  text-decoration: none;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

button {
  border: none;
  text-align: left;
  background-color: transparent;
  cursor: pointer;
}

ul,
ol {
  list-style: none;
}

input {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.focus-visible {
  outline: auto 1px;
}

.ios .lock {
  position: relative;
}

/* Forms
   ========================================================================== */

/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select {
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:root {
  --font-family: "Zona Pro", sans-serif;
  --font-weight: 400;
  --content-width: 130rem;
  --container-offset: 1.5rem;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --transition: 0.3s;
  --clr-default-100: #fff;
  --clr-default-200: rgba(255, 255, 255, 0.8);
  --clr-default-500: #00D6DA;
  --clr-default-550: rgba(255, 255, 255, 0.66);
  --clr-default-600: rgba(255, 255, 255, 0.05);
  --clr-default-800: #0B1D24;
  --clr-default-900: #000;
  --clr-primary-400: #22C99D;
  --clr-primary-450: rgba(0, 214, 218, 0.44);
  --clr-primary-500: #FF6900;
  --clr-primary-550: rgba(255, 105, 0, 0.44);
  --clr-primary-600: #333A48;
  --clr-primary-650: rgba(13, 46, 58, 0.45);
}

body {
  font-size: 1.6rem;
  color: var(--clr-default-100);
  background-color: var(--clr-default-800);
}

body.lock {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  min-width: 32rem;
  min-height: 100%;
}

.wrapper::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity var(--transition), visibility var(--transition);
}

.wrapper[data-overlay=true]::before {
  opacity: 1;
  visibility: visible;
  transition: opacity var(--transition), visibility var(--transition);
}

main {
  flex-grow: 1;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--container-offset);
  padding-left: var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.section {
  padding-top: 14rem;
}

.title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4rem;
  min-height: 10.7rem;
  font-size: clamp(
    2.1rem,
    1.6247524752rem + 1.4851485149vw,
    3.6rem
  );
  line-height: 133%;
  text-transform: uppercase;
}

.title .span__wrapper {
  color: var(--clr-default-100);
}

.title span {
  color: var(--clr-default-500);
}

.title:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 10.7rem;
  background-image: url(../img/ui/decor-title.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transform: translate(-50%, -50%);
}

.text {
  line-height: 188%;
}

.main__link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 2.5rem;
  padding-left: 2.5rem;
  width: max-content;
  min-width: 22rem;
  min-height: 6rem;
  font-family: "Strong";
  font-weight: 400;
  font-size: 2rem;
  line-height: 130%;
  text-align: center;
  color: var(--clr-default-100);
  transition: color var(--transition);
}

.main__link::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 6rem;
  background-image: url(../img/ui/border-btn.svg);
  background-size: contain;
  background-repeat: no-repeat;
  transform: translate(-50%, -50%);
}

.section__link {
  min-width: 25.8rem;
  min-height: 7.6rem;
}

.section__link p {
  z-index: 0;
}

.section__link:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 7.6rem;
  background-image: url(../img/ui/section-btn.svg);
  background-size: contain;
  background-repeat: no-repeat;
  transform: translate(-50%, -50%);
}

.long__link {
  min-width: 100%;
  min-height: 5.6rem;
}

.long__link p {
  z-index: 1;
}

.long__link::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 5.6rem;
  background-image: url(../img/ui/long-link.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transform: translate(-50%, -50%);
}

.menu__list {
  display: flex;
  align-items: center;
}

.menu__item {
  position: relative;
}

.menu__item::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -4px;
  width: 0;
  height: 2px;
  background-color: var(--clr-default-500);
  transform: translate(-50%, 0);
  transition: width 0.3s;
}

.menu__item a {
  font-size: 1.7rem;
  color: var(--clr-default-100);
}

.menu__item:not(:last-child) {
  margin-right: 3rem;
}

.language {
  position: relative;
  z-index: 1;
  margin-right: 3rem;
  font-weight: 600;
  cursor: pointer;
}

.language__current {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 7rem;
}

.language__current::before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  width: 1.2rem;
  height: 0.8rem;
  background-image: url(../img/ui/language-arrow.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transform: translateY(-50%) rotate(0);
  transition: transform var(--transition);
}

.language__dropdown {
  position: absolute;
  left: -125%;
  top: 100%;
  margin-top: 1.6rem;
  border-radius: 0.4rem;
  padding-right: 1.2rem;
  padding-left: 1.2rem;
  width: max-content;
  background-color: var(--clr-default-100);
  opacity: 0;
  visibility: hidden;
  transition: opacity var(--transition), visibility var(--transition);
}

.language__img {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  margin-right: 1.2rem;
  border-radius: 50%;
  width: 3.2rem;
  height: 3.2rem;
}

.language__img img {
  border-radius: 50%;
}

.language__dropdown-item {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

.language__dropdown-item:not(:last-child) {
  margin-bottom: 1rem;
}

.language__dropdown-item a {
  display: flex;
  align-items: center;
  padding: 3px 6px;
  font-family: "Poppins";
  font-weight: 500;
  color: var(--clr-primary-600);
  transition: color var(--transition);
}

.language__dropdown-item a:hover {
  color: var(--clr-default-500);
  transition: color var(--transition);
}

.language:focus .language__dropdown,
.language:hover .language__dropdown {
  opacity: 1;
  visibility: visible;
  transition: opacity var(--transition), visibility var(--transition);
}

.language:focus .language__current::before,
.language:hover .language__current::before {
  transform: translateY(-50%) rotate(-180deg);
  transition: transform var(--transition);
}

.burger {
  display: none;
}

.header {
  z-index: 1;
}

.header__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 5rem;
}

.footer {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.footer__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hero {
  position: relative;
  margin-top: -11rem;
  padding-top: 15rem;
  padding-bottom: 40rem;
  background-image: -webkit-image-set(url(../img/hero/bg@2x.jpg) 2x, url(../img/hero/bg@1x.jpg) 1x);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.hero::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 100%;
  height: 31rem;
  background-image: url(../img/hero/decor-border.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transform: translateX(-50%);
}

.hero__wrapper {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 55rem;
}

.hero__content {
  max-width: 63rem;
}

.hero__title {
  margin-bottom: 1.5rem;
  font-weight: 700;
  font-size: clamp(
    2.4rem,
    1.5128712871rem + 2.7722772277vw,
    5.2rem
  );
}

.hero__text {
  margin-bottom: 3rem;
}

.hero__text p {
  line-height: 188%;
}

.hero__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.hero__list {
  display: grid;
  grid-gap: 2rem 3rem;
  grid-template-columns: repeat(2, 1fr);
  list-style: disc;
  margin-bottom: 3rem;
  padding-left: 2rem;
}

.hero__item {
  font-size: clamp(
    1.8rem,
    1.7366336634rem + 0.198019802vw,
    2rem
  );
  line-height: 140%;
}

.hero__image {
  position: absolute;
  right: -30rem;
  top: 50%;
  width: 92rem;
  transform: translateY(-50%);
}

.hero__link {
  margin-bottom: 3rem;
}

.info {
  position: relative;
  margin-top: -18rem;
}

.info__text {
  margin-bottom: 5rem;
  text-align: center;
}

.info__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.info__table {
  overflow: auto;
  margin-bottom: 3rem;
  border: 0.1rem solid var(--clr-default-500);
  padding-top: 4rem;
  padding-right: 3rem;
  padding-bottom: 4rem;
  padding-left: 3rem;
}

.info__table table {
  border-collapse: collapse;
}

.info__table table tbody tr:not(:last-child) {
  border-bottom: 0.1rem solid var(--clr-default-600);
}

.info__table table tbody td {
  vertical-align: top;
  padding-top: 0.8rem;
  padding-right: 1.2rem;
  padding-bottom: 0.8rem;
  padding-left: 1.2rem;
  line-height: 150%;
}

.info__table table tbody td:first-child {
  min-width: 32rem;
}

.features__text {
  margin-bottom: 5rem;
}

.features__text p {
  text-align: center;
}

.features__text p:not(:last-child) {
  margin-bottom: 3rem;
}

.features__link {
  margin-right: auto;
  margin-left: auto;
}

.benefits {
  position: relative;
}

.benefits::before {
  content: "";
  position: absolute;
  left: -49rem;
  top: -13rem;
  z-index: -1;
  border-radius: 50%;
  width: 106rem;
  height: 106rem;
  background-color: var(--clr-primary-650);
  filter: blur(8rem);
}

.benefits::after {
  content: "";
  position: absolute;
  right: -60rem;
  top: 3rem;
  z-index: -1;
  border-radius: 50%;
  width: 106rem;
  height: 106rem;
  background-color: var(--clr-primary-650);
  filter: blur(8rem);
}

.benefits__text {
  margin-bottom: 5rem;
  text-align: center;
}

.benefits__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.benefits__body {
  display: flex;
}

.benefits__content:first-child {
  margin-right: 5rem;
}

.benefits__content-title {
  margin-bottom: 2.5rem;
  font-weight: 700;
  font-size: clamp(
    1.8rem,
    1.2297029703rem + 1.7821782178vw,
    3.6rem
  );
  text-transform: uppercase;
  color: var(--clr-default-500);
}

.benefits__content-title--cons {
  color: rgba(0, 214, 218, 0.44);
}

.benefits__list {
  list-style: disc;
  padding-left: 2rem;
}

.benefits__item {
  font-size: clamp(
    1.8rem,
    1.7366336634rem + 0.198019802vw,
    2rem
  );
}

.benefits__item:not(:last-child) {
  margin-bottom: 2.5rem;
}

.registration {
  position: relative;
  margin-top: 14rem;
  padding-top: 10rem;
  padding-bottom: 10rem;
  background-image: -webkit-image-set(url(../img/registration/bg@2x.png) 2x, url(../img/registration/bg@1x.png) 1x);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.registration__text {
  margin-right: auto;
  margin-bottom: 5rem;
  margin-left: auto;
  text-align: center;
}

.registration__list {
  counter-reset: num;
  list-style-type: none;
}

.registration__item {
  position: relative;
  padding-left: 10rem;
}

.registration__item::before {
  counter-increment: num;
  content: counter(num);
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 7.4rem;
  height: 7.4rem;
  font-weight: 700;
  font-size: 3.4rem;
  color: var(--clr-default-100);
  background-color: var(--clr-default-500);
}

.registration__item-title {
  margin-bottom: 1.5rem;
  font-weight: 700;
  font-size: clamp(
    1.8rem,
    1.2297029703rem + 1.7821782178vw,
    3.6rem
  );
  line-height: 133%;
  color: var(--clr-default-500);
}

.registration__item-text p:not(:last-child) {
  margin-bottom: 2rem;
}

.registration__item:not(:last-child) {
  margin-bottom: 5rem;
}

.registration__item:not(:last-child)::after {
  content: "";
  position: absolute;
  left: 3.5rem;
  top: 0;
  z-index: -1;
  width: 0.4rem;
  height: calc(100% + 7.4rem);
  background-color: var(--clr-default-500);
}

.app__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.app__info {
  margin-right: 2rem;
  max-width: 65rem;
}

.app__text {
  margin-bottom: 5rem;
}

.app__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.download__text p {
  text-align: center;
}

.download__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.betting {
  position: relative;
}

.betting:before {
  content: "";
  position: absolute;
  left: -49rem;
  top: -13rem;
  z-index: -1;
  border-radius: 50%;
  width: 106rem;
  height: 106rem;
  background-color: var(--clr-primary-650);
  filter: blur(8rem);
}

.betting__wrapper {
  position: relative;
}

.betting__image {
  position: absolute;
  left: -6.5rem;
  top: -6.5rem;
  width: 53rem;
}

.betting__info {
  margin-left: auto;
  max-width: 75rem;
}

.betting__text {
  margin-bottom: 5rem;
}

.betting__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.live__banner {
  margin-bottom: 8rem;
  max-width: 130rem;
}

.live__text {
  margin-bottom: 5rem;
  text-align: center;
}

.live__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.live__link {
  margin-right: auto;
  margin-left: auto;
}

.bonus__text {
  margin-bottom: 5rem;
  text-align: center;
}

.bonus__content {
  position: relative;
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.bonus__content::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  width: calc(100% + 30rem);
  height: 100%;
  background-image: linear-gradient(90.24deg, rgba(0, 192, 177, 0.12) -3.21%, rgba(0, 143, 163, 0.02) 43.49%, rgba(0, 163, 153, 0.1) 88.14%);
  transform: translateX(-50%);
}

.bonus__list {
  position: relative;
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: repeat(2, 1fr);
}

.bonus__list::before,
.bonus__list::after {
  content: "";
  position: absolute;
  width: 6rem;
  height: 12rem;
  background-color: var(--clr-default-800);
}

.bonus__list::before {
  left: -16.5rem;
  top: -11rem;
  transform: rotate(45deg);
}

.bonus__list::after {
  right: -16rem;
  bottom: -10.9rem;
  transform: rotate(45deg);
}

.bonus__item-title {
  margin-bottom: 1.5rem;
  font-weight: 700;
  font-size: clamp(
    2.1rem,
    1.6247524752rem + 1.4851485149vw,
    3.6rem
  );
  color: var(--clr-default-500);
}

.bonus__item-text p:not(:last-child) {
  margin-bottom: 2rem;
}

.vip {
  position: relative;
}

.vip:before {
  content: "";
  position: absolute;
  left: -49rem;
  top: -33rem;
  z-index: -1;
  border-radius: 50%;
  width: 106rem;
  height: 106rem;
  background-color: var(--clr-primary-650);
  filter: blur(8rem);
}

.vip::after {
  content: "";
  position: absolute;
  right: -49rem;
  top: 20rem;
  z-index: -1;
  border-radius: 50%;
  width: 106rem;
  height: 106rem;
  background-color: var(--clr-primary-650);
  filter: blur(8rem);
}

.vip__text {
  text-align: center;
}

.vip__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.program__wrapper {
  margin-bottom: 8rem;
}

.program__text {
  margin-bottom: 5rem;
  text-align: center;
}

.program__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.program__link {
  margin-right: auto;
  margin-left: auto;
}

.tournament {
  position: relative;
}

.tournament:before {
  content: "";
  position: absolute;
  right: -49rem;
  top: -33rem;
  z-index: -1;
  border-radius: 50%;
  width: 106rem;
  height: 106rem;
  background-color: var(--clr-primary-650);
  filter: blur(8rem);
}

.tournament__text {
  margin-bottom: 5rem;
  text-align: center;
}

.tournament__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.tournament__link {
  margin-right: auto;
  margin-left: auto;
}

.online__text {
  margin-bottom: 5rem;
  text-align: center;
}

.online__text p:not(:last-child) {
  margin-bottom: 3rem;
}

.online__table {
  overflow: auto;
  border: 0.1rem solid var(--clr-primary-450);
  padding-top: 4rem;
  padding-right: 3rem;
  padding-bottom: 4rem;
  padding-left: 3rem;
}

.online__table table {
  border-collapse: collapse;
  min-width: 100%;
}

.online__table table thead th {
  padding-top: 1rem;
  padding-right: 0.5rem;
  padding-bottom: 1rem;
  padding-left: 0.5rem;
  line-height: 150%;
  text-align: start;
  color: var(--clr-default-550);
}

.online__table table tbody tr {
  border-bottom: 0.1rem solid var(--clr-default-600);
}

.online__table table tbody td {
  padding-top: 1rem;
  padding-right: 0.5rem;
  padding-bottom: 1rem;
  padding-left: 0.5rem;
  line-height: 150%;
}

.table__text {
  margin-bottom: 5rem;
  text-align: center;
}

.table__text p:not(:last-child) {
  margin-bottom: 2.5rem;
}

.table__info {
  overflow: auto;
  margin-bottom: 4rem;
  border: 0.1rem solid var(--clr-primary-400);
  padding-top: 4rem;
  padding-right: 3rem;
  padding-bottom: 4rem;
  padding-left: 3rem;
}

.table__info table {
  border-collapse: collapse;
  min-width: 100%;
}

.table__info table thead th {
  line-height: 150%;
  text-align: start;
  color: var(--clr-default-550);
}

.table__info table thead th:first-child {
  min-width: 25rem;
}

.table__info table thead th:nth-child(2) {
  min-width: 40rem;
}

.table__info table tbody tr {
  border-bottom: 0.1rem solid var(--clr-default-600);
}

.table__info table tbody td {
  padding-top: 1rem;
  padding-right: 0.5rem;
  padding-bottom: 1rem;
  padding-left: 0.5rem;
  line-height: 150%;
}

.table__info table tbody td ul {
  list-style: disc;
  padding-left: 2rem;
}

.table__link {
  margin-right: auto;
  margin-left: auto;
}

.games__text {
  margin-bottom: 5rem;
  text-align: center;
}

.games__link {
  margin-right: auto;
  margin-left: auto;
}

.license__text {
  text-align: center;
}

.deposit {
  position: relative;
}

.deposit:before {
  content: "";
  position: absolute;
  right: -36rem;
  top: 0rem;
  z-index: -1;
  border-radius: 50%;
  width: 106rem;
  height: 106rem;
  background-color: var(--clr-primary-650);
  filter: blur(8rem);
}

.deposit__wrapper {
  position: relative;
}

.deposit__title {
  margin-bottom: 8rem;
}

.deposit__info {
  max-width: 75rem;
}

.deposit__text {
  margin-bottom: 5rem;
}

.deposit__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.deposit__image {
  position: absolute;
  right: -24rem;
  top: 14.5rem;
  width: 76rem;
  height: 55rem;
}

.resume__text {
  text-align: center;
}

.resume__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.faq__text {
  margin-bottom: 5rem;
}

.faq__list {
  display: grid;
  grid-gap: 2.5rem;
  grid-template-columns: repeat(3, 1fr);
}

.faq__item {
  border: 0.1rem solid var(--clr-default-500);
  border-radius: 1.2rem;
  padding: 3rem;
}

.faq__item-title {
  margin-bottom: 1.5rem;
  font-weight: 700;
  font-size: clamp(
    1.8rem,
    1.6099009901rem + 0.5940594059vw,
    2.4rem
  );
}

@media (max-width: 105em) {
  .hero {
    padding-bottom: 25rem;
  }

  .hero__wrapper {
    min-height: initial;
  }

  .hero__content {
    margin-right: 3rem;
    max-width: 50rem;
  }

  .hero__image {
    position: initial;
    right: initial;
    top: initial;
    width: initial;
    max-width: 92rem;
    transform: initial;
  }
}

@media (max-width: 75em) {
  .app__info {
    max-width: 50rem;
  }
}

@media (max-width: 62em) {
  .section {
    padding-top: 5rem !important;
  }

  .menu__list {
    flex-direction: column;
    margin: auto;
    padding-block: 4rem;
  }

  .menu__item a {
    font-weight: 600;
    font-size: 2rem;
  }

  .menu__item:not(:last-child) {
    margin-right: 0;
    margin-bottom: 3rem;
  }

  .menu {
    position: fixed;
    right: -100%;
    top: 0;
    z-index: 10;
    display: flex;
    overflow: auto;
    width: 100%;
    height: 100%;
    max-width: 32rem;
    background-color: var(--clr-default-900);
    transition: right var(--transition);
  }

  .menu--active {
    right: 0;
    transition: right var(--transition);
  }

  .language {
    margin-right: 30px;
    margin-left: auto;
  }

  .burger {
    position: relative;
    z-index: 15;
    display: block;
    width: 2.5rem;
    height: 2rem;
    background-color: transparent;
  }

  .burger::after,
  .burger::before,
  .burger span {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 0.4rem;
    background-color: #fff;
  }

  .burger::before {
    top: 0;
    transition: transform var(--transition), top var(--transition);
  }

  .burger::after {
    bottom: 0;
    transition: transform var(--transition), bottom var(--transition);
  }

  .burger span {
    top: 0.8rem;
    transition: transform var(--transition);
  }

  .burger--active::before {
    top: 0.8rem;
    transform: rotate(45deg);
    transition: transform var(--transition), top var(--transition);
  }

  .burger--active::after {
    bottom: 0.8rem;
    transform: rotate(-45deg);
    transition: transform var(--transition), bottom var(--transition);
  }

  .burger--active span {
    transform: scale(0);
    transition: transform var(--transition);
  }

  .header__wrapper {
    padding-top: 3rem;
  }

  .header__link {
    margin-right: 2.5rem;
  }

  .hero {
    padding-bottom: 17rem;
  }

  .hero__wrapper {
    flex-direction: column;
    padding-bottom: 5rem;
  }

  .hero__content {
    margin-right: initial;
    margin-bottom: 3rem;
    max-width: initial;
  }

  .info__table table tbody td:first-child {
    min-width: 23rem;
  }

  .registration {
    margin-top: 5rem !important;
  }

  .app__content {
    flex-direction: column;
  }

  .app__info {
    margin-right: initial;
    margin-bottom: 3rem;
    max-width: initial;
  }

  .betting__content {
    flex-direction: column-reverse;
  }

  .betting__image {
    margin-right: initial !important;
    max-width: 35rem !important;
  }

  .betting__info {
    margin-bottom: 3rem;
    max-width: initial !important;
  }

  .live__banner {
    margin-bottom: 5rem;
  }

  .bonus__content {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .program__wrapper {
    margin-bottom: 6rem;
  }

  .online__table {
    padding-top: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
  }

  .table__info table thead th:first-child {
    min-width: 15rem;
  }

  .table__info table thead th:nth-child(2) {
    min-width: 20rem;
  }

  .table__info {
    padding-top: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
  }

  .deposit__title {
    margin-bottom: 3rem;
  }

  .deposit__content {
    flex-direction: column;
  }

  .deposit__info {
    margin-right: initial !important;
    margin-bottom: 3rem;
    max-width: initial !important;
  }

  .deposit__image {
    margin-right: auto;
    margin-left: auto;
  }

  .faq__list {
    grid-gap: 1.5rem;
  }

  .faq__item {
    padding: 1.5rem;
  }
}

@media (max-width: 97.5em) {
  .footer {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

@media (max-width: 83.125em) {
  .deposit__title {
    margin-bottom: 5rem;
  }

  .deposit__content {
    display: flex;
  }

  .deposit__info {
    margin-right: 3rem;
    max-width: 55rem;
  }

  .deposit__image {
    position: initial;
    right: initial;
    top: initial;
    width: initial;
    height: initial;
    max-width: 76rem;
  }
}

@media (max-width: 82.5em) {
  .section {
    padding-top: 10rem;
  }

  .title:before {
    display: none;
  }

  .title {
    display: block;
    margin-right: auto;
    margin-left: auto;
    min-height: initial;
    text-align: center;
  }

  .long__link {
    margin-right: auto;
    margin-left: auto;
    min-width: 25.8rem;
  }

  .long__link::before {
    background-image: url(../img/ui/section-btn.svg);
  }

  .registration {
    margin-top: 10rem;
  }

  .betting__content {
    display: flex;
    align-items: center;
  }

  .betting__image {
    position: initial;
    left: initial;
    top: initial;
    margin-right: 3rem;
    width: initial;
    max-width: 53rem;
  }

  .betting__info {
    max-width: 65rem;
  }
}

@media (max-width: 47.9375em) {
  .title {
    margin-bottom: 2.5rem;
  }

  .language__current {
    min-width: 5.5rem;
  }

  .footer {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .hero__list {
    display: block;
    grid-gap: initial;
  }

  .hero__item:not(:last-child) {
    margin-bottom: 2rem;
  }

  .info__table table tbody td:first-child {
    min-width: 15rem;
  }

  .features__text {
    margin-bottom: 3rem;
  }

  .benefits__text {
    margin-bottom: 3rem;
  }

  .benefits__body {
    flex-direction: column;
  }

  .benefits__content:first-child {
    margin-bottom: 3rem;
  }

  .benefits__item:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  .registration {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .live__banner {
    margin-bottom: 3rem;
  }

  .live__text {
    margin-bottom: 3rem;
  }

  .bonus__text {
    margin-bottom: 3rem;
  }

  .bonus__list {
    display: block;
    grid-gap: initial;
  }

  .bonus__item:not(:last-child) {
    margin-bottom: 2.5rem;
  }

  .bonus__item-title {
    margin-bottom: 1rem;
  }

  .bonus__item-text p:not(:last-child) {
    margin-bottom: 1rem;
  }

  .program__wrapper {
    margin-bottom: 4rem;
  }

  .program__text {
    margin-bottom: 3rem;
  }

  .tournament__text {
    margin-bottom: 3rem;
  }

  .online__text {
    margin-bottom: 3rem;
  }

  .online__table table {
    min-width: 74rem;
  }

  .table__text {
    margin-bottom: 3rem;
  }

  .table__info table {
    min-width: 66rem;
  }

  .games__text {
    margin-bottom: 3rem;
  }

  .faq__text {
    margin-bottom: 3rem;
  }

  .faq__list {
    display: block;
    grid-gap: initial;
  }

  .faq__item {
    margin-bottom: 2rem;
  }
}

@media (max-width: 35.9375em) {
  .header__link {
    margin-right: initial;
  }

  .hero__wrapper {
    padding-bottom: 3rem;
  }

  .hero__item:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  .info__table table {
    min-width: 50rem;
  }

  .info__table {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  .features__text p:not(:last-child) {
    margin-bottom: 2rem;
  }

  .registration__title {
    margin-bottom: 2rem;
  }

  .registration__text {
    margin-bottom: 3rem;
  }

  .registration__item::before {
    width: 4rem;
    height: 4rem;
    font-size: 2rem;
  }

  .registration__item:not(:last-child)::after {
    left: 1.9rem;
    height: calc(100% + 4rem);
  }

  .registration__item:not(:last-child) {
    margin-bottom: 2.5rem;
  }

  .registration__item {
    padding-left: 5rem;
  }
}

@media (hover: hover) {
  .main__link:hover {
    color: var(--clr-default-500);
    transition: color var(--transition);
  }

  .menu__item:hover::before {
    width: 100%;
    transition: width 0.3s;
  }
}