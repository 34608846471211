.license {

  &__wrapper {
  }

  &__title {
  }

  &__text {
    text-align: center;
  }
}
