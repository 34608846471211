.app {

  &__wrapper {
  }

  &__title {
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: em(992)) {
      flex-direction: column;
    }
  }

  &__info {
    margin-right: 2rem;
    max-width: 65rem;

    @media (max-width: em(1200)) {
      max-width: 50rem;
    }

    @media (max-width: em(992)) {
      margin-right: initial;
      margin-bottom: 3rem;
      max-width: initial;
    }
  }

  &__text {
    margin-bottom: 5rem;

    p:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__link {
  }

  &__image {
  }
}

