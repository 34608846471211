.betting {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: -49rem;
    top: -13rem;
    z-index: -1;
    border-radius: 50%;
    width: 106rem;
    height: 106rem;
    background-color: var(--clr-primary-650);
    filter: blur(8rem);
  }

  &__wrapper {
    position: relative;
  }

  &__title {
  }

  &__content {
    @media (max-width: em(1320)) {
      display: flex;
      align-items: center;
    }

    @media (max-width: em(992)) {
      flex-direction: column-reverse;
    }
  }

  &__image {
    position: absolute;
    left: -6.5rem;
    top: -6.5rem;
    width: 53rem;

    @media (max-width: em(1320)) {
      position: initial;
      left: initial;
      top: initial;
      margin-right: 3rem;
      width: initial;
      max-width: 53rem;
    }

    @media (max-width: em(992)) {
      margin-right: initial !important;
      max-width: 35rem !important;
    }
  }

  &__info {
    margin-left: auto;
    max-width: 75rem;

    @media (max-width: em(1320)) {
      max-width: 65rem;
    }

    @media (max-width: em(992)) {
      margin-bottom: 3rem;
      max-width: initial !important;
    }
  }

  &__text {
    margin-bottom: 5rem;

    p:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__link {
  }
}

