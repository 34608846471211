.vip {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: -49rem;
    top: -33rem;
    z-index: -1;
    border-radius: 50%;
    width: 106rem;
    height: 106rem;
    background-color: var(--clr-primary-650);
    filter: blur(8rem);
  }

  &::after {
    content: "";
    position: absolute;
    right: -49rem;
    top: 20rem;
    z-index: -1;
    border-radius: 50%;
    width: 106rem;
    height: 106rem;
    background-color: var(--clr-primary-650);
    filter: blur(8rem);
  }

  &__wrapper {
  }

  &__title {
  }

  &__text {
    text-align: center;

    p:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
}
